<template>
  <Breadcrumb />
  <div v-if="selectedTabValue == 'special'" class="d-flex justify-content-end">
    <router-link to="New" class="btn btn-success btn-action-new-page"
      ><i class="bi bi-plus"></i>
      {{
        $t(
          "Configurations.NewCustomConfiguration",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}</router-link
    >
  </div>
  <div v-if="selectedTabValue == 'system'" class="d-flex justify-content-end">
    <router-link to="NewSetXRM" class="btn btn-success btn-action-new-page"
      ><i class="bi bi-plus"></i>
      {{
        $t(
          "Configurations.NewSetxrmConfiguration",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}</router-link
    >
  </div>
  <div
    v-if="selectedTabValue == 'allSystem'"
    class="d-flex justify-content-end"
  >
    <router-link to="NewSetXRM" class="btn btn-success btn-action-new-page"
      ><i class="bi bi-plus"></i>
      {{
        $t(
          "Configurations.NewSetxrmConfiguration",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}</router-link
    >
  </div>
  <ul class="nav nav-tabs mb-2" role="tablist">
    <li class="nav-item" role="presentation">
      <button
        class="nav-link active"
        @click="selectedTab('system')"
        data-bs-toggle="pill"
        data-bs-target="#pills-system-tab"
        type="button"
        role="tab"
        aria-selected="true"
      >
        {{
          $t(
            "Configurations.TypeSystem",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="nav-link"
        @click="selectedTab('special')"
        data-bs-toggle="pill"
        data-bs-target="#pills-company-tab"
        type="button"
        role="tab"
        aria-selected="false"
      >
        {{
          $t(
            "Configurations.TypeCompany",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </button>
    </li>
  </ul>
  <div class="tab-content">
    <div
      class="tab-pane fade active show"
      id="pills-system-tab"
      role="tabpanel"
    >
      <Grid :settings="gridSettings" :columns="gridColumns" />
    </div>
    <div class="tab-pane fade" id="pills-company-tab" role="tabpanel">
      <Grid
        :settings="gridCustomConfigurationSettings"
        :columns="gridColumns"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "ConfigurationList",
  components: {},
  data() {
    return {
      selectedTabValue: "system",
      gridCustomConfigurationSettings: {
        action: "ConfigurationForCompanyList",
        requestUrl: String.format("/Lcdp-ConfigurationForCompanyList"),
        requestUrlRouteParamNames: [],
        routeRequiredParameters: [
          {
            key: "configurationId",
            value: "publicId",
            isRouteParameter: false,
          },
        ],

        serialNoLink: `#/Configuration/Edit/`,
        isGatewayRequest: true,

        allowSearchPanel: true,
        allowExcelExport: false,
        allowDragAndDrop: false,
        allowPaging: true,
        allowDeleting: true,
        deleteActionUrl: "/Lcdp-ConfigurationDeleteCustom",
        autoOrderProcess: true,

        buttons: [
          {
            name: "edit",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: true,
            routeObj: {
              name: "ConfigurationEdit",
              params: {
                configurationId: "",
              },
            },
          },
        ],
      },
      gridSettings: {
        action: "ConfigurationListAllCustom",
        requestUrl: String.format(
          "/Lcdp-ConfigurationListAllCustom?isDefaultForCompany=true"
        ),
        requestUrlRouteParamNames: [],
        routeRequiredParameters: [
          {
            key: "configurationId",
            value: "publicId",
            isRouteParameter: false,
          },
        ],

        serialNoLink: `#/Configuration/EditSetXRM/`,
        isGatewayRequest: true,

        allowSearchPanel: true,
        allowExcelExport: false,
        allowDragAndDrop: false,
        allowPaging: true,
        allowDeleting: true,
        deleteActionUrl: "/Lcdp-ConfigurationDeleteCustom",
        autoOrderProcess: true,

        buttons: [
          {
            name: "edit",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: true,
            routeObj: {
              name: "ConfigurationEditSetXRM",
              params: {
                configurationId: "",
              },
            },
          },
        ],
      },
      gridColumns: [
        {
          text: this.$t(
            "Configurations.Key",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "key",
          type: "string",
          visible: true,
          width: 0,
          minWidth: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Configurations.Value",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "value",
          type: "string",
          visible: true,
          width: 0,
          minWidth: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Configurations.Description",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "description",
          type: "string",
          visible: true,
          width: 0,
          minWidth: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
      ],
    };
  },
  methods: {
    selectedTab(tab) {
      this.selectedTabValue = tab;
    },
  },
};
</script>
